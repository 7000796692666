import React, { useEffect, useRef, useState } from "react";

import { useAnimationControls } from "framer-motion";
import { useAnimations } from "../../hooks/useAnimations";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import { IllustrationsContainer } from "../Illustrations/IllustrationContainer";
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";
import AdvantageSectionLayout from "./AdvantageSectionLayout";

import { ADVANTAGES } from "../../constants";

import AiFeedback from "../../../../assets/images/ReviewManagement/AiFeedback.svg";
import AiTeamResponse from "../../../../assets/images/ReviewManagement/AlTeamResponse.svg";
import GuestReview from "../../../../assets/images/ReviewManagement/GuestReview.svg";
import Issues from "../../../../assets/images/ReviewManagement/Issues.svg";
import Slack from "../../../../assets/images/ReviewManagement/Slack.svg";
import SpottedIssues from "../../../../assets/images/ReviewManagement/SpottedIssues.svg";

import type { Animations } from "../../types";

enum AnimationItems {
  GuestReview,
  AiTeamResponse,
  AiFeedback,
  SpottedIssues,
  Issues,
  Slack,
}

const desktopAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.GuestReview,
    element: GuestReview,
    order: 0,
    start: { opacity: 0, top: 165, left: 30, scale: 1 },
    end: { opacity: 1, top: 65 },
  },
  {
    key: AnimationItems.AiTeamResponse,
    element: AiTeamResponse,
    order: 1,
    start: { opacity: 0, top: -50, left: 330, scale: 1 },
    end: { opacity: 1, left: 230 },
  },
  {
    key: AnimationItems.AiFeedback,
    element: AiFeedback,
    order: 2,
    start: { opacity: 0, top: 323, left: 30, scale: 1 },
    end: { opacity: 1, top: 223 },
  },
  {
    key: AnimationItems.SpottedIssues,
    element: SpottedIssues,
    order: 3,
    start: { opacity: 0, top: 397, left: 30, scale: 1 },
    end: { opacity: 1, top: 297 },
  },
  {
    key: AnimationItems.Issues,
    element: Issues,
    order: 4,
    start: { opacity: 0, left: 313, top: 388, zIndex: 2, scale: 1 },
    end: { opacity: 1, top: 288 },
  },
  {
    key: AnimationItems.Slack,
    element: Slack,
    order: 5,
    start: { opacity: 0, top: 70, left: 510, scale: 1 },
    end: { opacity: 1, left: 410 },
  },
];

const mobileAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.GuestReview,
    element: GuestReview,
    order: 0,
    start: { opacity: 0, top: 165, left: 0 },
    end: { opacity: 1, top: 65 },
  },
  {
    key: AnimationItems.AiTeamResponse,
    element: AiTeamResponse,
    order: 1,
    start: { opacity: 0, top: -23, left: 200, scale: 0.65 },
    end: { opacity: 1, left: 113 },
  },
  {
    key: AnimationItems.AiFeedback,
    element: AiFeedback,
    order: 2,
    start: { opacity: 0, top: 323, left: 0 },
    end: { opacity: 1, top: 223 },
  },
  {
    key: AnimationItems.SpottedIssues,
    element: SpottedIssues,
    order: 3,
    start: { opacity: 0, top: 397, left: 0 },
    end: { opacity: 1, top: 297 },
  },
  {
    key: AnimationItems.Slack,
    element: Slack,
    order: 4,
    start: { opacity: 0, top: 275, left: 96, scale: 0.6 },
    end: { opacity: 1, left: 110 },
  },
  {
    key: AnimationItems.Issues,
    element: Issues,
    order: 5,
    start: { opacity: 0, left: 0, top: 550, zIndex: 2 },
    end: { opacity: 1, top: 450 },
  },
];

const ReviewManagement = () => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();

  const [animations, setAnimations] = useState<Animations<AnimationItems>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? mobileAnimations : desktopAnimations);
  }, [mobileView]);

  useAnimations({ animations, sectionRef, controls, delay: 0.7 });

  return (
    <AdvantageSectionLayout
      tabLabel="Review Management"
      title="AI-Driven Guest Review Management"
      description="Our AI solution intelligently analyses public reviews, private feedback, and guest messages from your inbox to automatically create tasks in our smart hospitality-driven Kanban board. Furthermore, it ensures you never miss a review response by automatically generating personalised replies, showcasing your genuine care for your guests."
      onCtaClick={() => null}
      illustration={
        <IllustrationsContainer sx={{ minHeight: { xs: 700, lg: 650 }, mt: { xs: 8, lg: 0 } }}>
          {animations.map((a) => (
            <AnimatedIllustration
              key={a.key}
              src={a.element}
              controls={controls}
              animationOrder={a.order}
              initial={a.start}
            />
          ))}
        </IllustrationsContainer>
      }
      id={ADVANTAGES.REVIEW_MANAGEMENT}
      ref={sectionRef}
    />
  );
};

export default ReviewManagement;
