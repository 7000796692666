import React, { useEffect, useRef, useState } from "react";
import { useAnimationControls } from "framer-motion";
import { useAnimations } from "../../hooks/useAnimations";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import { IllustrationsContainer } from "../Illustrations/IllustrationContainer";
import AdvantageSectionLayout from "./AdvantageSectionLayout";
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";

import { ADVANTAGES } from "../../constants";

import GuestMessage1 from "../../../../assets/images/upsells/guest_message1.svg";
import GuestMessage2 from "../../../../assets/images/upsells/guest_message2.svg";
import GuestMessage3 from "../../../../assets/images/upsells/guest_message3.svg";
import BotSuggestion1 from "../../../../assets/images/upsells/bot_suggestion1.svg";
import BotSuggestion2 from "../../../../assets/images/upsells/bot_suggestion2.svg";
import AutoPilotImg from "../../../../assets/images/upsells/autopilot_img.svg";
import AutoPaymentImg from "../../../../assets/images/upsells/auto_payment.svg";
import CalendarImg from "../../../../assets/images/upsells/calendar_img.svg";
import CostImg from "../../../../assets/images/upsells/cost_img.svg";
import RevenueImg from "../../../../assets/images/upsells/revenue_img.svg";
import StripeImg from "../../../../assets/images/upsells/stripe_integration.svg";
import PaymentLogImg from "../../../../assets/images/upsells/payment_log.svg";

import type { Animations } from "../../types";

enum AnimationItems {
  GuestMsg1,
  GuestMsg2,
  GuestMsg3,
  BotSug1,
  BotSug2,
  AutoPilot,
  AutoPayment,
  Calendar,
  Cost,
  Revenue,
  Stripe,
  PaymentLog,
}

const desktopAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.GuestMsg1,
    element: GuestMessage1,
    order: 0,
    start: { opacity: 0, top: 100 },
    end: { opacity: 1, scale: 1, top: -20 },
  },
  {
    key: AnimationItems.BotSug1,
    element: BotSuggestion1,
    order: 1,
    start: { opacity: 0, top: 200, left: 16 },
    end: { opacity: 1, scale: 1, top: 86 },
  },
  {
    key: AnimationItems.AutoPilot,
    element: AutoPilotImg,
    order: 2,
    start: { opacity: 0, top: 60, left: 320, scale: 0 },
    end: { opacity: 1, zIndex: 2, scale: 1 },
  },
  {
    key: AnimationItems.GuestMsg2,
    element: GuestMessage2,
    order: 3,
    start: { opacity: 0, top: 275 },
    end: { opacity: 1, scale: 1, top: 175 },
  },
  {
    key: AnimationItems.BotSug2,
    element: BotSuggestion2,
    order: 4,
    start: { opacity: 0, top: 320, left: 30 },
    end: { opacity: 1, scale: 1, top: 260 },
  },
  {
    key: AnimationItems.Calendar,
    element: CalendarImg,
    order: 5,
    start: { opacity: 0, top: 180, left: 345, scale: 0 },
    end: { opacity: 1, scale: 1, zIndex: 2 },
  },
  {
    key: AnimationItems.Cost,
    element: CostImg,
    order: 6,
    start: { opacity: 0, top: 360, left: 340, scale: 0, zIndex: 2 },
    end: { opacity: 1, scale: 1 },
  },

  {
    key: AnimationItems.AutoPayment,
    element: AutoPaymentImg,
    order: 7,
    start: { opacity: 0, top: 70, left: 400 },
    end: { opacity: 1, scale: 1, top: -70 },
  },

  {
    key: AnimationItems.Stripe,
    element: StripeImg,
    order: 8,
    start: { opacity: 0, top: 20, left: 675, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },

  {
    key: AnimationItems.PaymentLog,
    element: PaymentLogImg,
    order: 9,
    start: { opacity: 0, top: 215, left: 400 },
    end: { opacity: 1, scale: 1, top: 115 },
  },

  {
    key: AnimationItems.GuestMsg3,
    element: GuestMessage3,
    order: 10,
    start: { opacity: 0, top: 410, left: 420 },
    end: { opacity: 1, scale: 1, top: 310 },
  },

  {
    key: AnimationItems.Revenue,
    element: RevenueImg,
    order: 11,
    start: { opacity: 0, scale: 0, top: 350, left: 630 },
    end: { opacity: 1, scale: 1 },
  },
];

const mobileAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.GuestMsg1,
    element: GuestMessage1,
    order: 0,
    start: { opacity: 0, top: 140, left: -30, scale: 0.9 },
    end: { opacity: 1, top: 40 },
  },
  {
    key: AnimationItems.BotSug1,
    element: BotSuggestion1,
    order: 1,
    start: { opacity: 0, top: 200, left: -27, scale: 0.9 },
    end: { opacity: 1, top: 130 },
  },
  {
    key: AnimationItems.AutoPilot,
    element: AutoPilotImg,
    order: 2,
    start: { opacity: 0, top: 111, left: 242, scale: 0 },
    end: { opacity: 1, zIndex: 2, scale: 0.8 },
  },
  {
    key: AnimationItems.GuestMsg2,
    element: GuestMessage2,
    order: 3,
    start: { opacity: 0, top: 310 },
    end: { opacity: 1, top: 210 },
  },
  {
    key: AnimationItems.BotSug2,
    element: BotSuggestion2,
    order: 4,
    start: { opacity: 0, top: 420, left: -20, scale: 0.9 },
    end: { opacity: 1, top: 300 },
  },
  {
    key: AnimationItems.Calendar,
    element: CalendarImg,
    order: 5,
    start: { opacity: 0, top: 245, left: 265, scale: 0 },
    end: { opacity: 1, scale: 0.7, zIndex: 2 },
  },
  {
    key: AnimationItems.Cost,
    element: CostImg,
    order: 6,
    start: { opacity: 0, top: 375, left: 265, scale: 0, zIndex: 2 },
    end: { opacity: 1, scale: 0.7 },
  },

  {
    key: AnimationItems.AutoPayment,
    element: AutoPaymentImg,
    order: 7,
    start: { opacity: 0, top: 590, left: -30, scale: 0.9 },
    end: { opacity: 1, top: 490 },
  },

  {
    key: AnimationItems.Stripe,
    element: StripeImg,
    order: 8,
    start: { opacity: 0, top: 560, left: 250, scale: 0 },
    end: { opacity: 1, scale: 0.9 },
  },

  {
    key: AnimationItems.PaymentLog,
    element: PaymentLogImg,
    order: 9,
    start: { opacity: 0, top: 700, left: 0, scale: 0.9 },
    end: { opacity: 1, top: 620 },
  },

  {
    key: AnimationItems.GuestMsg3,
    element: GuestMessage3,
    order: 10,
    start: { opacity: 0, top: 750, left: -20, scale: 0.9 },
    end: { opacity: 1, top: 710 },
  },

  {
    key: AnimationItems.Revenue,
    element: RevenueImg,
    order: 11,
    start: { opacity: 0, scale: 0, top: 690, left: 260 },
    end: { opacity: 1, scale: 0.8 },
  },
];

const Upsells = () => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();
  const [animations, setAnimations] = useState<Animations<AnimationItems>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? mobileAnimations : desktopAnimations);
  }, [mobileView]);

  useAnimations({
    animations,
    sectionRef,
    controls,
    delay: 0.7,
  });

  return (
    <AdvantageSectionLayout
      tabLabel="Personalised Upsells"
      title="Unleash AI's sales potential for maximum revenue growth"
      description="Enhance guest experiences with personalised upselling from the moment they book, increasing average revenue per user and creating unforgettable moments. Utilise advanced algorithms for in-stay customisation, optimising revenue per available room effortlessly and without additional resources."
      onCtaClick={() => null}
      illustration={
        <IllustrationsContainer sx={{ minHeight: mobileView ? 850 : 600 }}>
          {animations.map((a) => (
            <AnimatedIllustration
              key={mobileView ? `${a.key}-mobile` : a.key}
              src={a.element}
              controls={controls}
              animationOrder={a.order}
              initial={a.start}
            />
          ))}
        </IllustrationsContainer>
      }
      id={ADVANTAGES.UPSELLS}
      ref={sectionRef}
    />
  );
};

export default Upsells;
