import React, { useRef, useState } from "react";
import { styled } from "@mui/material";
import Image from "next/image";

import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";

import { useWindowSize } from "../../../hooks/useWindowSize";
import { useAnimations } from "../hooks/useAnimations";
import { useAnimationControls } from "framer-motion";
import { useRouter } from "next/router";

import AnimatedIllustration from "./AnimatedIllustration";
import { Animations } from "../types";

import TaskManagerImg from "../../../assets/images/TaskManager/TaskManager.svg";
import TaskOneImg from "../../../assets/images/TaskManager/Task1.svg";
import TaskTwoImg from "../../../assets/images/TaskManager/Task2.svg";
import TaskLogImg from "../../../assets/images/TaskManager/TaskLog.svg";
import ArrowLeftImg from "../../../assets/images/TaskManager/ArrowLeft.svg";
import ArrowRightImg from "../../../assets/images/TaskManager/ArrowRight.svg";
import AiFeedback from "../../../assets/images/ReviewManagement/AiFeedback.svg";
import GuestReview from "../../../assets/images/ReviewManagement/GuestReview.svg";
import SpottedIssues from "../../../assets/images/ReviewManagement/SpottedIssues.svg";
import Star from "../../../assets/images/TaskManager/Star.svg";
import Underline from "../../../assets/images/TaskManager/Underline.svg";
import CurveLine from "../../../assets/images/TaskManager/CurveLine.svg";
import Shine from "../../../assets/images/TaskManager/shine.svg";
import Flag from "../../../assets/images/TaskManager/Flag.svg";
import CurveLine2 from "../../../assets/images/TaskManager/CurveLine2.svg";

enum AnimationItems {
  TaskOne,
  TaskLog,
  ArrowLeft,
  TaskTwo,
  GuestReview,
  AiFeedback,
  SpottedIssues,
  ArrowRight,
}

const desktopAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.TaskOne,
    element: TaskOneImg,
    order: 0,
    start: { opacity: 0, top: 234, left: 478.5, scale: 0.488, zIndex: 2 },
    end: { opacity: 1 },
  },
  {
    key: AnimationItems.TaskLog,
    element: TaskLogImg,
    order: 1,
    start: { opacity: 0, top: 340, left: 675, scale: 0.7, zIndex: 2 },
    end: { opacity: 1 },
  },
  {
    key: AnimationItems.ArrowLeft,
    element: ArrowLeftImg,
    order: 2,
    start: { opacity: 0, top: 375, left: 675, scale: 0.6, zIndex: 2 },
    end: { opacity: 1 },
  },
  {
    key: AnimationItems.TaskTwo,
    element: TaskTwoImg,
    order: 3,
    start: { opacity: 0, top: 324, left: 36, scale: 0.488, zIndex: 2 },
    end: { opacity: 1 },
  },
  {
    key: AnimationItems.GuestReview,
    element: GuestReview,
    order: 4,
    start: { opacity: 0, top: 223, left: -95, scale: 0.6, zIndex: 2 },
    end: { opacity: 1 },
  },
  {
    key: AnimationItems.AiFeedback,
    element: AiFeedback,
    order: 5,
    start: { opacity: 0, top: 335, left: -95, scale: 0.6, zIndex: 2 },
    end: { opacity: 1 },
  },
  {
    key: AnimationItems.SpottedIssues,
    element: SpottedIssues,
    order: 6,
    start: { opacity: 0, top: 382, left: -95, scale: 0.6, zIndex: 2 },
    end: { opacity: 1 },
  },
  {
    key: AnimationItems.ArrowRight,
    element: ArrowRightImg,
    order: 7,
    start: { opacity: 0, top: 395, left: 50, scale: 0.6, zIndex: 2 },
    end: { opacity: 1 },
  },
];

const TaskManagerSection = () => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();

  const [animations, setAnimations] = useState<Animations<AnimationItems>>(desktopAnimations);

  const { mobileView } = useWindowSize();

  useAnimations({ animations, sectionRef, controls, delay: 0.7 });

  const router = useRouter();

  const handleCtaClick = () => {
    router.push("/app/inbox");
  };

  return (
    <Container sx={{ pt: { xs: 5, md: 8, lg: 11.25 }, pb: { xs: 5, md: 0 }, px: { xs: 2, md: 0 } }}>
      <Content>
        <Title
          sx={{
            color: "dark.heading",
            fontSize: { xs: 36, md: 48, lg: 60 },
            lineHeight: { xs: 1, md: 1.1, lg: 1.3 },
          }}
        >
          AI-powered Task Manager
        </Title>
        <Subtitle sx={{ fontSize: { xs: 14, lg: 18 } }}>
          From Feedback to Excellence: Transform reviews into action, elevate your listing quality.
        </Subtitle>
        {/* @ts-ignore */}
        <CtaButton color="primary" variant="gradient" circular onClick={handleCtaClick}>
          Start now
        </CtaButton>

        <StarIllustration src={Star} alt="star" />
        <UnderlineIllustration
          src={Underline}
          alt="underline"
          sx={{ display: { xs: "none", lg: "block" } }}
        />
      </Content>

      <AnimationsContainer ref={sectionRef} sx={{ mt: 10, display: { xs: "none", lg: "block" } }}>
        <TaskMangerIllustration src={TaskManagerImg} alt="task-manager" style={{}} />
        <ShineIllustration src={Shine} alt="shine" />

        {animations.map((a) => (
          <AnimatedIllustration
            key={mobileView ? `${a.key}-mobile` : a.key}
            src={a.element}
            controls={controls}
            animationOrder={a.order}
            initial={a.start}
          />
        ))}
      </AnimationsContainer>

      <CurveLine2Illustration
        src={CurveLine2}
        alt="line"
        sx={{ display: { xs: "none", lg: "block" } }}
      />
      <FlagIllustration src={Flag} alt="flag" />
      <CurveLineIllustration
        src={CurveLine}
        alt="line"
        sx={{ display: { xs: "none", lg: "block" } }}
      />
    </Container>
  );
};

const Container = styled(SoftBox)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  position: "relative",
}));

const Content = styled(SoftBox)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 20,
  position: "relative",
}));

const Title = styled(SoftTypography)(() => ({
  fontWeight: 700,
  letterSpacing: -0.8,
  textAlign: "center",
}));

const Subtitle = styled(SoftTypography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 18,
  lineHeight: "25px",
  letterSpacing: -0.8,
  textAlign: "center",
  maxWidth: 450,
}));

const CtaButton = styled(SoftButton)(() => ({
  textTransform: "none",
}));

const AnimationsContainer = styled(SoftBox)(() => ({
  position: "relative",
}));

const TaskMangerIllustration = styled(Image)(({ theme }) => ({
  height: 500,
  width: 735,
  border: "2px solid",
  borderColor: theme.palette.grey[200],
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  borderBottom: 0,
}));

const StarIllustration = styled(Image)(() => ({
  position: "absolute",
  right: 0,
  top: "70%",
}));

const UnderlineIllustration = styled(Image)(() => ({
  position: "absolute",
  left: "40%",
  top: "35%",
}));

const CurveLineIllustration = styled(Image)(() => ({
  position: "absolute",
  left: "15%",
  top: "35%",
}));

const ShineIllustration = styled(Image)(() => ({
  position: "absolute",
  right: "-3%",
  top: "-3%",
}));

const FlagIllustration = styled(Image)(() => ({
  position: "absolute",
  left: "10%",
  top: "80%",
}));

const CurveLine2Illustration = styled(Image)(() => ({
  position: "absolute",
  right: "10%",
  top: "90%",
}));

export default TaskManagerSection;
