import React, { forwardRef, useEffect } from "react";
import Image from "next/image";
import { styled } from "@mui/material";
import { motion, useAnimate } from "framer-motion";

import SoftBox from "../../../../components/SoftBox";

import InboxIllustration from "../../../../assets/images/HeroSection/Inbox.svg";

type InboxScenarioProps = {
  onAnimationEnd: () => void;
};

const InboxScenario = forwardRef<HTMLDivElement, InboxScenarioProps>((props, ref) => {
  const { onAnimationEnd } = props;

  const [scope, animate] = useAnimate();

  useEffect(() => {
    setTimeout(() => {
      onAnimationEnd();
    }, 4000);
  }, []);

  return (
    <AnimationsContainer>
      <motion.div ref={scope}>
        <Image src={InboxIllustration} alt="inbox" priority quality={100} />
      </motion.div>
    </AnimationsContainer>
  );
});

const AnimationsContainer = styled(SoftBox)(() => ({
  flex: 1,
  maxWidth: 796,
  width: "100%",
  height: "100%",
  margin: "0 auto",
  position: "relative",
  marginTop: 27,
  marginBottom: -40,
}));

export default InboxScenario;
