import { AnimationControls, useInView } from "framer-motion";
import { useEffect } from "react";
import type { Animations } from "../types";
import { useWindowSize } from "../../../hooks/useWindowSize";

export type UseAnimationsParams<T> = {
  sectionRef: React.RefObject<HTMLDivElement>;
  controls: AnimationControls;
  animations: Animations<T>;
  mobileAnimations?: Animations<T>;
  delay: number;
  onAnimationEnd?: () => void;
};

export const useAnimations = <T>(params: UseAnimationsParams<T>) => {
  const { sectionRef, controls, animations, delay, onAnimationEnd } = params;

  const isInView = useInView(sectionRef, { once: false, amount: 0.6 });

  const startAnimation = () => {
    setTimeout(() => {
      controls
        .start((i) => {
          const target = animations.find((a) => a.order === i);
          const targetAnimation = target?.end || {};
          return {
            // @ts-ignore
            ...targetAnimation,
            transition: { delay: i * delay },
          };
        })
        .then(() => {
          if (onAnimationEnd) {
            onAnimationEnd();
          }
        });
    }, 700);
  };

  const resetAnimation = () => {
    controls.start((i) => {
      const target = animations.find((a) => a.order === i);
      const targetAnimation = target?.start || {};
      return {
        // @ts-ignore
        ...targetAnimation,
        transition: { delay: 0 },
      };
    });
  };

  useEffect(() => {
    if (isInView) {
      startAnimation();
    } else {
      resetAnimation();
    }
  }, [isInView]);
};
