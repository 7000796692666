import React from "react";
import { styled } from "@mui/material";

import { useRouter } from "next/router";

import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftTypography from "../../../components/SoftTypography";

import houseIllustration from "../../../assets/images/house-illustration.jpg";

const SignupCTA = () => {
  const router = useRouter();

  const handleCtaClick = () => {
    router.push("/app/inbox");
  };

  return (
    <Container sx={{ px: { xs: 2, md: 0 } }}>
      <Title>What are you waiting for?</Title>

      <Subtitle>
        Enhance your listing quality, streamline communications, and unlock 5-star reviews.
      </Subtitle>

      <SignupButton color="warning" sx={{ color: "dark.heading" }} onClick={handleCtaClick}>
        Signup
      </SignupButton>
      <Overlay />
    </Container>
  );
};

const Container = styled(SoftBox)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: 110,
  paddingBottom: 120,
  position: "relative",
  gap: 15,
  flexDirection: "column",
  backgroundImage: `url(${houseIllustration.src})`,
  width: "100%",
  height: "100%",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const Overlay = styled(SoftBox)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: 1,
}));

const Title = styled(SoftTypography)(() => ({
  fontSize: 40,
  fontWeight: 700,
  lineHeight: "50px",
  letterSpacing: -0.8,
  textAlign: "center",
  color: "white",
  zIndex: 2,
}));

const Subtitle = styled(SoftTypography)(() => ({
  fontSize: 18,
  lineHeight: "25px",
  letterSpacing: 0,
  textAlign: "center",
  color: "white",
  zIndex: 2,
}));

const SignupButton = styled(SoftButton)(() => ({
  borderRadius: 24,
  zIndex: 2,
  padding: "11px 24px",
}));

export default SignupCTA;
