import React from "react";
import { styled } from "@mui/material";
import { format } from "date-fns";

import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";

import { PRODUCT_NAME } from "../../../../constants";

import FooterLinksColumn from "./FooterLinksColumn";
import SocialLinks from "./SocialLinks";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const links = [
  {
    columnLabel: "Important links",
    links: [
      {
        to: "#",
        label: "Link 1",
      },
      {
        to: "#",
        label: "Link 2",
      },
      {
        to: "#",
        label: "Link 3",
      },
      {
        to: "#",
        label: "Link 4",
      },
    ],
  },
  {
    columnLabel: "Important links",
    links: [
      {
        to: "#",
        label: "Link 1",
      },
      {
        to: "#",
        label: "Link 2",
      },
      {
        to: "#",
        label: "Link 3",
      },
      {
        to: "#",
        label: "Link 4",
      },
    ],
  },
  {
    columnLabel: "Important links",
    links: [
      {
        to: "#",
        label: "Link 1",
      },
      {
        to: "#",
        label: "Link 2",
      },
      {
        to: "#",
        label: "Link 3",
      },
      {
        to: "#",
        label: "Link 4",
      },
    ],
  },
];

const Footer = () => {
  const { mobileView } = useWindowSize();

  return (
    <SoftBox
      sx={(theme) => ({
        background: theme.palette.primary.main,
        pt: { xs: 2, lg: 7.5 },
        pb: 1.5,
        px: { xs: 2, lg: 8 },
      })}
    >
      <FooterLayout sx={{ flexDirection: { xs: "column", lg: "row" } }}>
        <BrandColumn sx={{ mr: { xs: 0, lg: 28 }, maxWidth: { xs: "100%", lg: 260 } }}>
          <BrandName>{PRODUCT_NAME}.</BrandName>
          <SmallText>
            Botel is an AI-powered chatbot and task management platform designed to streamline guest
            communication and optimize operations for property managers and hoteliers. With its
            advanced natural language processing and machine learning capabilities, Botel
            efficiently handles guest inquiries, reservation management, and incident handling,
            saving time and improving customer satisfaction. By automating tasks, analyzing reviews,
            and providing actionable insights, Botel empowers businesses to deliver exceptional
            guest experiences and maximize revenue. Discover the power of Botel and revolutionize
            your property management operations today.
          </SmallText>

          {!mobileView && (
            <>
              <SocialLinks />
              <SoftBox
                style={{ display: "flex", flexDirection: "column", marginBottom: 20, gap: 5 }}
              >
                <a
                  className="tw-footer-link tw-text-white tw-text-xs !tw-underline active:tw-underline hover:tw-underline"
                  href="/terms"
                >
                  Terms and Conditions
                </a>
                <a
                  className="tw-footer-link tw-text-white tw-text-xs !tw-underline active:tw-underline hover:tw-underline"
                  href="/privacy"
                >
                  Privacy Policy
                </a>
              </SoftBox>
              <SmallText sx={{ mt: { xs: 0, lg: 6.875 } }}>
                © {`${format(new Date(), "yyyy")} Botel AI Inc.`}
              </SmallText>
            </>
          )}
        </BrandColumn>
        {/* <LinksColumns
          sx={{ gap: { xs: 2, lg: 12 }, justifyContent: { xs: "space-between", lg: "start" } }}
        >
          {links.map((l, i) => (
            <FooterLinksColumn
              key={l.columnLabel + i}
              columnLabel={l.columnLabel}
              links={l.links}
            />
          ))}
        </LinksColumns> */}

        {mobileView && (
          <SoftBox sx={{ mt: 1 }}>
            <SocialLinks />
            <SoftBox style={{ display: "flex", flexDirection: "column", marginBottom: 20, gap: 5 }}>
              <a
                className="tw-footer-link tw-text-white tw-text-xs !tw-underline active:tw-underline hover:tw-underline"
                href="/terms"
              >
                Terms and Conditions
              </a>
              <a
                className="tw-footer-link tw-text-white tw-text-xs !tw-underline active:tw-underline hover:tw-underline"
                href="/privacy"
              >
                Privacy Policy
              </a>
            </SoftBox>
            <SmallText sx={{ mt: { xs: 0, lg: 6.875 } }}>
              © {`${format(new Date(), "yyyy")} Botel AI Inc.`}
            </SmallText>
          </SoftBox>
        )}
      </FooterLayout>
    </SoftBox>
  );
};

const FooterLayout = styled(SoftBox)(() => ({
  display: "flex",
}));

const BrandColumn = styled(SoftBox)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const BrandName = styled(SoftTypography)(() => ({
  color: "white",
  fontWeight: 700,
  fontSize: 24,
  letterSpacing: -0.8,
  marginBottom: 12,
}));

const SmallText = styled(SoftTypography)(() => ({
  color: "white",
  fontSize: 12,
  marginBottom: 12,
}));

const LinksColumns = styled(SoftBox)(() => ({
  display: "flex",
}));

export default Footer;
