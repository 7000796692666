import React from "react";
import styled from "@emotion/styled";

// components
import SoftBox from "../../../components/SoftBox";
import AdvantageBadge from "./AdvantageBadge";

// icons
import ConfirmIcon from "../../../assets/icons/homepage/Confirm.svg";
import StarIcon from "../../../assets/icons/homepage/Star.svg";

const AdvantagesBadges = () => {
  return (
    <Advantages sx={{ flexDirection: { xs: "column", md: "row" } }}>
      <AdvantageBadge label="No Onboarding Fee" icon={ConfirmIcon} />
      <AdvantageBadge label="No Obligation" icon={ConfirmIcon} />
      <AdvantageBadge label="5.0 Rating" icon={StarIcon} />
    </Advantages>
  );
};

const Advantages = styled(SoftBox)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 18,
  marginTop: 32,
}));

export default AdvantagesBadges;
