import React from "react";
import Image from "next/image";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import { styled } from "@mui/material";

type AdvantageBadgeProps = {
  label: string;
  icon: string;
};

const AdvantageBadge: React.FC<AdvantageBadgeProps> = (props) => {
  const { label, icon } = props;

  return (
    <StyledBadge>
      <Image src={icon} alt="advantage icon" />
      <BadgeLabel>{label}</BadgeLabel>
    </StyledBadge>
  );
};

const StyledBadge = styled(SoftBox)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: 40,
  display: "flex",
  alignItems: "center",
  paddingTop: 7,
  paddingBottom: 6,
  paddingLeft: 17,
  paddingRight: 24,
}));

const BadgeLabel = styled(SoftTypography)(({ theme }) => ({
  color: "white",
  fontSize: 14,
  marginLeft: 10,
}));

export default AdvantageBadge;
