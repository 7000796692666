import React from "react";
import styled from "@emotion/styled";
import Image from "next/image";

//components
import SoftBox from "../../../components/SoftBox";

// images
import DrawStarIcon from "../../../assets/icons/homepage/DrawStar.svg";
import DrawCurveLineIcon from "../../../assets/icons/homepage/DrawCurveLine.svg";
import DrawCurveLineTwo from "../../../assets/icons/homepage/DrawCurveLineTwo.svg";
import DrawFlag from "../../../assets/icons/homepage/DrawFlag.svg";

type MainSectionLayoutProps = {
  children: React.ReactNode;
};

export const MainSectionLayout: React.FC<MainSectionLayoutProps> = ({ children }) => {
  return (
    <Section>
      {children}

      <DrawStarIllustration src={DrawStarIcon} alt="star" />
      <DrawLine1Illustration src={DrawCurveLineIcon} alt="line" />
      <DrawLine2Illustration src={DrawCurveLineTwo} alt="line" />
      <DrawFlagIllustration src={DrawFlag} alt="flag" />
    </Section>
  );
};

const Section = styled(SoftBox)(({ theme }) => ({
  // @ts-ignore
  background: theme.functions.linearGradient(
    // @ts-ignore
    theme.palette.gradients.primary.main,
    // @ts-ignore
    theme.palette.gradients.primary.state
  ),
  minHeight: 860,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const DrawStarIllustration = styled(Image)(() => ({
  position: "absolute",
  right: "14%",
  top: 220,
}));

const DrawLine1Illustration = styled(Image)(() => ({
  position: "absolute",
  left: "13%",
  top: 340,
}));

const DrawLine2Illustration = styled(Image)(() => ({
  position: "absolute",
  right: "5%",
  top: 700,
}));

const DrawFlagIllustration = styled(Image)(() => ({
  position: "absolute",
  left: 60,
  top: 660,
}));
