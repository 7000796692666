import React, { useEffect, useRef, useState } from "react";
import { useAnimationControls } from "framer-motion";
import { useAnimations } from "../../hooks/useAnimations";

import AdvantageSectionLayout from "./AdvantageSectionLayout";
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";
import { IllustrationsContainer } from "../Illustrations/IllustrationContainer";

import { ADVANTAGES } from "../../constants";

import AutoInstructions from "../../../../assets/images/AiPoweredMessages/AutoInstructions.svg";
import AutomatedMessageBadge from "../../../../assets/images/AiPoweredMessages/AutomatedMessageBadge.svg";
import BotAutomatedMessage from "../../../../assets/images/AiPoweredMessages/BotAutomatedMessage.svg";
import DrawArrow from "../../../../assets/images/AiPoweredMessages/DrawArrow.svg";
import GuestMessage from "../../../../assets/images/AiPoweredMessages/GuestMessage1.svg";
import NegativeCircle from "../../../../assets/images/AiPoweredMessages/NegativeCircle.svg";
import PersonalizedBadge from "../../../../assets/images/AiPoweredMessages/PersonalizedBadge.svg";
import PersonalizedBotMessage from "../../../../assets/images/AiPoweredMessages/PersonalizedBotMessage.svg";

import type { Animations } from "../../types";
import { useWindowSize } from "../../../../hooks/useWindowSize";

enum AnimationItems {
  AutoInstructions,
  AutomatedMessageBadge,
  BotAutomatedMessage,
  DrawArrow,
  GuestMessage,
  NegativeCircle,
  PersonalizedBadge,
  PersonalizedBotMessage,
}

const desktopAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.GuestMessage,
    element: GuestMessage,
    order: 0,
    start: { opacity: 0, top: 100, scale: 1, left: 0 },
    end: { opacity: 1, top: -40 },
  },
  {
    key: AnimationItems.BotAutomatedMessage,
    element: BotAutomatedMessage,
    order: 1,
    start: { opacity: 0, top: 200, scale: 1, left: 0 },
    end: { opacity: 1, top: 150 },
  },
  {
    key: AnimationItems.AutomatedMessageBadge,
    element: AutomatedMessageBadge,
    order: 2,
    start: { opacity: 0, top: 130, left: 315, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.NegativeCircle,
    element: NegativeCircle,
    order: 3,
    start: { opacity: 0, left: 334, top: -52, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.DrawArrow,
    element: DrawArrow,
    order: 4,
    start: { opacity: 0, left: 460, top: -70, scale: 0, rotate: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.AutoInstructions,
    element: AutoInstructions,
    order: 5,
    start: { opacity: 0, left: 500, top: -100, scale: 1 },
    end: { opacity: 1, top: 40 },
  },
  {
    key: AnimationItems.PersonalizedBotMessage,
    element: PersonalizedBotMessage,
    order: 6,
    start: { opacity: 0, top: 380, left: 22, scale: 1 },
    end: { opacity: 1, top: 340 },
  },
  {
    key: AnimationItems.PersonalizedBadge,
    element: PersonalizedBadge,
    order: 7,
    start: { opacity: 0, top: 300, left: 335, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
];

const mobileAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.GuestMessage,
    element: GuestMessage,
    order: 0,
    start: { opacity: 0, top: 100, scale: 0.7, left: -60 },
    end: { opacity: 1, top: 40 },
  },
  {
    key: AnimationItems.BotAutomatedMessage,
    element: BotAutomatedMessage,
    order: 1,
    start: { opacity: 0, top: 220, scale: 0.7, left: -60 },
    end: { opacity: 1, top: 170 },
  },
  {
    key: AnimationItems.AutomatedMessageBadge,
    element: AutomatedMessageBadge,
    order: 2,
    start: { opacity: 0, top: 260, left: 140, scale: 0 },
    end: { opacity: 1, scale: 0.7 },
  },
  {
    key: AnimationItems.NegativeCircle,
    element: NegativeCircle,
    order: 3,
    start: { opacity: 0, left: 203, top: 15, scale: 0 },
    end: { opacity: 1, scale: 0.7 },
  },
  {
    key: AnimationItems.DrawArrow,
    element: DrawArrow,
    order: 4,
    start: { opacity: 0, left: 250, top: 70, scale: 0, rotate: 45 },
    end: { opacity: 1, scale: 0.5 },
  },
  {
    key: AnimationItems.AutoInstructions,
    element: AutoInstructions,
    order: 5,
    start: { opacity: 0, left: 195, top: 240, scale: 0.6 },
    end: { opacity: 1, top: 140 },
  },
  {
    key: AnimationItems.PersonalizedBotMessage,
    element: PersonalizedBotMessage,
    order: 6,
    start: { opacity: 0, top: 410, left: -45, scale: 0.7 },
    end: { opacity: 1, top: 310 },
  },
  {
    key: AnimationItems.PersonalizedBadge,
    element: PersonalizedBadge,
    order: 7,
    start: { opacity: 0, top: 387, left: 211, scale: 0 },
    end: { opacity: 1, scale: 0.8 },
  },
];

const AiPoweredMessages = () => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();

  const [animations, setAnimations] = useState<Animations<AnimationItems>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? mobileAnimations : desktopAnimations);
  }, [mobileView]);

  useAnimations({ animations, sectionRef, controls, delay: 0.7 });

  return (
    <AdvantageSectionLayout
      tabLabel="AI Powered Message"
      title="Say goodbye to automated messages with messages powered by AI!"
      description="Our AI works for your business, increasing inquiry to booking conversion, reducing response times, preventing bad reviews and identifying upsell opportunities."
      onCtaClick={() => null}
      illustration={
        <IllustrationsContainer
          sx={{ minHeight: mobileView ? 500 : 650, marginTop: { xs: 3, lg: 0 } }}
        >
          {animations.map((a) => (
            <AnimatedIllustration
              key={mobileView ? `${a.key}-mobile` : a.key}
              src={a.element}
              controls={controls}
              animationOrder={a.order}
              initial={a.start}
            />
          ))}
        </IllustrationsContainer>
      }
      id={ADVANTAGES.AI_POWERED_MESSAGES}
      ref={sectionRef}
    />
  );
};

export default AiPoweredMessages;
