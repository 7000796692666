import React, { useEffect, useRef, useState } from "react";

import { useAnimationControls } from "framer-motion";
import { useAnimations } from "../../hooks/useAnimations";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import { IllustrationsContainer } from "../Illustrations/IllustrationContainer";
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";
import AdvantageSectionLayout from "./AdvantageSectionLayout";

import { ADVANTAGES } from "../../constants";

import ArrowBottom from "../../../../assets/images/SatisfiedGuests/ArrowBottom.svg";
import ArrowLeft from "../../../../assets/images/SatisfiedGuests/ArrowLeft.svg";
import BotResponse from "../../../../assets/images/SatisfiedGuests/BotResponse.svg";
import GuestMessage from "../../../../assets/images/SatisfiedGuests/GuestMessage.svg";
import SentimentAnalysis from "../../../../assets/images/SatisfiedGuests/SentimentAnalysis.svg";
import SentimentInstructions from "../../../../assets/images/SatisfiedGuests/SentimentInstructions.svg";

import type { Animations } from "../../types";

enum AnimationItems {
  GuestMessage,
  SentimentAnalysis,
  ArrowBottom,
  SentimentInstructions,
  ArrowLeft,
  BotResponse,
}

const desktopAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.GuestMessage,
    element: GuestMessage,
    order: 0,
    start: { opacity: 0, top: 100, scale: 1, left: 0 },
    end: { opacity: 1, top: -40 },
  },
  {
    key: AnimationItems.SentimentAnalysis,
    element: SentimentAnalysis,
    order: 1,
    start: { opacity: 0, top: -130, left: 320, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.ArrowBottom,
    element: ArrowBottom,
    order: 2,
    start: { opacity: 0, top: 6, left: 520, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.SentimentInstructions,
    element: SentimentInstructions,
    order: 3,
    start: { opacity: 0, top: 195, left: 500, scale: 1 },
    end: { opacity: 1, top: 95 },
  },
  {
    key: AnimationItems.ArrowLeft,
    element: ArrowLeft,
    order: 4,
    start: { opacity: 0, scale: 0, left: 407, top: 222 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.BotResponse,
    element: BotResponse,
    order: 5,
    start: { opacity: 0, top: 230, left: 10, scale: 1 },
    end: { opacity: 1, top: 130 },
  },
];

const mobileAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.GuestMessage,
    element: GuestMessage,
    order: 0,
    start: { opacity: 0, top: 100, scale: 0.8, left: -50 },
    end: { opacity: 1, top: 54 },
  },
  {
    key: AnimationItems.SentimentAnalysis,
    element: SentimentAnalysis,
    order: 1,
    start: { opacity: 0, top: -22, left: 212, scale: 0 },
    end: { opacity: 1, scale: 0.7 },
  },
  {
    key: AnimationItems.ArrowBottom,
    element: ArrowBottom,
    order: 2,
    start: { opacity: 0, top: 112, left: 300, scale: 0, rotate: 45 },
    end: { opacity: 1, scale: 0.8 },
  },
  {
    key: AnimationItems.SentimentInstructions,
    element: SentimentInstructions,
    order: 3,
    start: { opacity: 0, top: 200, left: 180, scale: 0.7 },
    end: { opacity: 1, top: 165 },
  },
  {
    key: AnimationItems.ArrowLeft,
    element: ArrowLeft,
    order: 4,
    start: { opacity: 0, scale: 0, left: 290, top: 270 },
    end: { opacity: 1, scale: 0.6 },
  },
  {
    key: AnimationItems.BotResponse,
    element: BotResponse,
    order: 5,
    start: { opacity: 0, top: 360, left: -35, scale: 0.8 },
    end: { opacity: 1, top: 265 },
  },
];

const SatisfiedGuests = () => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();

  const [animations, setAnimations] = useState<Animations<AnimationItems>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? mobileAnimations : desktopAnimations);
  }, [mobileView]);

  useAnimations({ animations, sectionRef, controls, delay: 0.7 });

  return (
    <AdvantageSectionLayout
      tabLabel="Satisfied Guests"
      title="AI Sentiment-based Guest Management"
      description="Enhancing guest experiences by analysing sentiments and offering complimentary late/early checkouts or extensions based on calendar settings and gap nights."
      onCtaClick={() => null}
      illustration={
        <IllustrationsContainer sx={{ mt: { xs: 3, lg: 0 }, minHeight: { xs: 580, lg: 650 } }}>
          {animations.map((a) => (
            <AnimatedIllustration
              key={a.key}
              src={a.element}
              controls={controls}
              animationOrder={a.order}
              initial={a.start}
            />
          ))}
        </IllustrationsContainer>
      }
      id={ADVANTAGES.SATISFIED_GUESTS}
      ref={sectionRef}
    />
  );
};

export default SatisfiedGuests;
