import { useRouter } from "next/router";
import { styled } from "@mui/material";

import SoftBox from "../../../../components/SoftBox";
import SoftButton from "../../../../components/SoftButton";

const AuthButtons = () => {
  const router = useRouter();

  const handleLoginClick = () => {
    router.push("/auth/signin");
  };

  const handleGetStartedClick = () => {
    router.push("/app/inbox");
  };

  return (
    <Container>
      <LoginButton variant="text" onClick={handleLoginClick}>
        Log in
      </LoginButton>
      <GetStartedButton
        onClick={handleGetStartedClick}
        sx={{ display: { xs: "none", md: "initial" } }}
      >
        Get Started
      </GetStartedButton>
    </Container>
  );
};

const Container = styled(SoftBox)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 4,
}));

const LoginButton = styled(SoftButton)(({ theme }) => ({
  fontFamily: "Noto Sans",
  fontSize: 14,
  fontWeight: 400,
  textTransform: "capitalize",
}));

const GetStartedButton = styled(SoftButton)(({ theme }) => ({
  fontFamily: "Noto Sans",
  fontSize: 13,
  fontWeight: 600,
  textTransform: "capitalize",
  color: theme.palette.primary.main,
  borderRadius: 24,
}));

export default AuthButtons;
