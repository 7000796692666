import React from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import { styled } from "@mui/material";

// components
import AnimatedHeroSections from "./HeroSections/AnimatedHeroSections";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import { MainSectionLayout } from "./MainSectionLayout";
import SoftBox from "../../../components/SoftBox";
import AdvantagesBadges from "./AdvantagesBadges";
import Container from "./Container";
import { Header } from "./Header";

// images
import UnderlineIcon from "../../../assets/icons/homepage/Underline.svg";
import ShineLinesIcon from "../../../assets/icons/homepage/ShineLines.svg";

const MainSection = () => {
  const router = useRouter();

  const handleCtaClick = () => {
    router.push("/app/inbox");
  };

  return (
    <MainSectionLayout>
      <Header />
      <Container sx={{ px: { xs: 2, lg: 8 }, mt: { xs: 0, lg: 5 } }}>
        <WelcomeSection sx={{ mt: { xs: 15, md: 0 } }}>
          <PrimaryTitle sx={{ fontSize: { xs: 48, lg: 60 } }}>
            Elevate guest experience
            <UnderlineDecoration src={UnderlineIcon} alt="underline" />
            <ShineDecoration
              src={ShineLinesIcon}
              alt="shine"
              sx={{ right: { xs: "20%", md: -15 } }}
            />
          </PrimaryTitle>
          <SecondaryTitle>
            Unlock your full potential with Botel: Streamline operations, enhance listing quality,
            improve communication, and start earning those coveted 5-star reviews.
          </SecondaryTitle>

          <StartFreeTrialButton color="warning" onClick={handleCtaClick}>
            Start Now - From $6.95 per listing / month
          </StartFreeTrialButton>

          <AdvantagesBadges />
        </WelcomeSection>
      </Container>

      <AnimatedHeroSections />
    </MainSectionLayout>
  );
};

const WelcomeSection = styled(SoftBox)(({ theme }) => ({
  maxWidth: 700,
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const PrimaryTitle = styled(SoftTypography)(({ theme }) => ({
  fontSize: 60,
  fontWeight: 700,
  lineHeight: 1.25,
  letterSpacing: -0.8,
  textAlign: "center",
  color: "white",
  marginBottom: 20,
  position: "relative",
  zIndex: 2,
  maxWidth: 700,
}));

const UnderlineDecoration = styled(Image)(() => ({
  position: "absolute",
  left: "25%",
  bottom: -10,
}));

const ShineDecoration = styled(Image)(() => ({
  position: "absolute",
}));

const SecondaryTitle = styled(SoftTypography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 400,
  lineHeight: 1.3,
  letterSpacing: -0.8,
  textAlign: "center",
  maxWidth: 600,
  margin: "0 auto",
  color: theme.palette.grey[300],
  marginBottom: 20,
  zIndex: 2,
}));

const StartFreeTrialButton = styled(SoftButton)(({ theme }) => ({
  borderRadius: 24,
  color: theme.palette.primary.main,
  textTransform: "none",
}));

export default MainSection;
