import React, { useEffect, useRef, useState } from "react";
import ElevatorScenario from "./ElevatorScenario";
import ACScenario from "./ACScenario";
import BookingScenario from "./BookingScenario";
import InboxScenario from "./InboxScenario";
import { useInView } from "framer-motion";
import SoftBox from "../../../../components/SoftBox";

enum Scenarios {
  INBOX,
  ELEVATOR,
  AC,
  BOOKING,
}

const steps = [Scenarios.INBOX, Scenarios.ELEVATOR, Scenarios.AC, Scenarios.BOOKING];

const SCENARIO_DELAY = 2000;

let timer = null;

const AnimatedHeroSections = () => {
  const [currentScenario, setCurrentScenario] = useState(Scenarios.INBOX);

  const sectionRef = useRef<HTMLDivElement>(null);

  const inView = useInView(sectionRef, { amount: 0.2 });

  const currentInView = useRef(true);

  const nextScenario = () => {
    if (currentInView.current) {
      // @ts-ignore
      timer = setTimeout(() => {
        setCurrentScenario((prev) => (prev === steps.length - 1 ? steps[0] : steps[prev + 1]));
      }, SCENARIO_DELAY);
    }
  };

  useEffect(() => {
    currentInView.current = inView;
    if (!inView) {
      // @ts-ignore
      clearTimeout(timer);
    }
  }, [inView]);

  const getCurrentScenario = () => {
    switch (currentScenario) {
      case Scenarios.INBOX: {
        return <InboxScenario onAnimationEnd={nextScenario} />;
      }
      case Scenarios.ELEVATOR: {
        return <ElevatorScenario onAnimationEnd={nextScenario} />;
      }
      case Scenarios.AC: {
        return <ACScenario onAnimationEnd={nextScenario} />;
      }
      case Scenarios.BOOKING: {
        return <BookingScenario onAnimationEnd={nextScenario} />;
      }
    }
  };

  return (
    <SoftBox ref={sectionRef} sx={{ display: { xs: "none", lg: "block" } }}>
      {getCurrentScenario()}
    </SoftBox>
  );
};

export default AnimatedHeroSections;
