import { motion, AnimationControls, AnimationProps, VariantLabels, Target } from "framer-motion";
import Image, { StaticImageData } from "next/image";
import React from "react";

type AnimatedIllustrationProps = {
  src: StaticImageData | string;
  initial: boolean | Target | VariantLabels;
  controls: AnimationControls;
  animationOrder: number;
};

const AnimatedIllustration: React.FC<AnimatedIllustrationProps> = (props) => {
  const { src, initial, controls, animationOrder } = props;
  return (
    <motion.div
      initial={initial}
      animate={controls}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      custom={animationOrder}
      style={{ position: "absolute" }}
    >
      <Image src={src} alt="illustration" quality={100} />
    </motion.div>
  );
};

export default AnimatedIllustration;
