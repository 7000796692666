import { styled } from "@mui/material";

import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";

import { PRODUCT_NAME } from "../../../../constants";

import AuthButtons from "./AuthButtons";
import NavLinks from "../Navigation/NavLinks";
import MobileMenu from "../Navigation/MobileMenu";

const Header = () => {
  return (
    <Container>
      <Logo sx={{ left: { xs: 20, md: 40, lg: 65 } }}>{PRODUCT_NAME}.</Logo>

      <SoftBox sx={{ display: { xs: "none", md: "block" } }}>{/* <NavLinks /> */}</SoftBox>

      <AuthButtonsContainer
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          right: { xs: 20, md: 40, lg: 65 },
        }}
      >
        <AuthButtons />
        {/* <MobileMenu /> */}
      </AuthButtonsContainer>
    </Container>
  );
};

const Container = styled(SoftBox)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  paddingTop: 40,
  paddingBottom: 40,
  position: "relative",
}));

const Logo = styled(SoftTypography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  lineHeight: 1.4,
  letterSpacing: 0,
  color: "white",
  position: "absolute",
  left: 0,
}));

const AuthButtonsContainer = styled(SoftBox)(() => ({
  position: "absolute",
  right: 0,
}));

export default Header;
