import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material";

// hooks
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useAnimationControls } from "framer-motion";
import { useAnimations } from "../../hooks/useAnimations";

// components
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";
import SoftBox from "../../../../components/SoftBox";

// images
import ElevatorGuestMsgOne from "../../../../assets/images/HeroSection/ElevatorGuestMsgOne.svg";
import ArrowRight from "../../../../assets/images/HeroSection/ArrowRight.svg";
import Property from "../../../../assets/images/HeroSection/Property.svg";
import ArrowBottom from "../../../../assets/images/HeroSection/ArrowBottom.svg";
import Eminities from "../../../../assets/images/HeroSection/Eminities.svg";
import ArrowLeft from "../../../../assets/images/HeroSection/ArrowLeft.svg";
import BotResponseElevator from "../../../../assets/images/HeroSection/BotResponseElevator.svg";
import ElevatorGuestMsgTwo from "../../../../assets/images/HeroSection/ElevatorGuestMsgTwo.svg";

import type { Animations } from "../../types";

enum ElevatorScenarioAnimations {
  GuestMessageOne,
  ArrowRight,
  Property,
  ArrowBottom,
  Eminities,
  ArrowLeft,
  BotResponse,
  GuestMessageTwo,
}

const desktopAnimations: Animations<ElevatorScenarioAnimations> = [
  {
    key: ElevatorScenarioAnimations.GuestMessageOne,
    element: ElevatorGuestMsgOne,
    order: 0,
    start: { opacity: 0, scale: 1, left: 0, top: 158 },
    end: { opacity: 1, top: 58 },
  },
  {
    key: ElevatorScenarioAnimations.ArrowRight,
    element: ArrowRight,
    order: 1,
    start: { opacity: 0, scale: 0, left: 479, top: 45 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: ElevatorScenarioAnimations.Property,
    element: Property,
    order: 2,
    start: { opacity: 0, scale: 1, left: 598, top: 100 },
    end: { opacity: 1, top: 0 },
  },
  {
    key: ElevatorScenarioAnimations.ArrowBottom,
    element: ArrowBottom,
    order: 3,
    start: { opacity: 0, left: 718, top: 143, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: ElevatorScenarioAnimations.Eminities,
    element: Eminities,
    order: 4,
    start: { opacity: 0, scale: 1, left: 575, top: 230 },
    end: { opacity: 1 },
  },
  {
    key: ElevatorScenarioAnimations.ArrowLeft,
    element: ArrowLeft,
    order: 5,
    start: { opacity: 0, scale: 0, left: 479, top: 333 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: ElevatorScenarioAnimations.BotResponse,
    element: BotResponseElevator,
    order: 6,
    start: { opacity: 0, scale: 1, left: 100, top: 135 },
    end: { opacity: 1 },
  },
  {
    key: ElevatorScenarioAnimations.GuestMessageTwo,
    element: ElevatorGuestMsgTwo,
    order: 7,
    start: { opacity: 0, scale: 1, left: 0, top: 335 },
    end: { opacity: 1 },
  },
];

type ElevatorScenarioProps = {
  onAnimationEnd: () => void;
};

const ElevatorScenario: React.FC<ElevatorScenarioProps> = ({ onAnimationEnd }) => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();

  const [animations, setAnimations] = useState<Animations<ElevatorScenarioAnimations>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? [] : desktopAnimations);
  }, [mobileView]);

  useAnimations({ animations, sectionRef, controls, delay: 1, onAnimationEnd });

  return (
    <AnimationsContainer ref={sectionRef}>
      {animations.map((a) => (
        <AnimatedIllustration
          key={mobileView ? `${a.key}-mobile` : a.key}
          src={a.element}
          controls={controls}
          animationOrder={a.order}
          initial={a.start}
        />
      ))}
    </AnimationsContainer>
  );
};

const AnimationsContainer = styled(SoftBox)(() => ({
  flex: 1,
  maxWidth: 928,
  width: "100%",
  height: "100%",
  margin: "0 auto",
  position: "relative",
  marginTop: 27,
}));

export default ElevatorScenario;
