import { styled } from "@mui/material";
import React from "react";
import SoftBox from "../../../../components/SoftBox";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

const SocialLinks = () => {
  return (
    <Container>
      <a href="https://www.linkedin.com/company/botel-ai/" target="_blank" rel="noreferrer">
        <LinkedIn sx={{ fill: "white", width: 20 }} />
      </a>

      <a href="#" target="_blank" rel="noreferrer">
        <Facebook sx={{ fill: "white", width: 20 }} />
      </a>

      <a href="#" target="_blank" rel="noreferrer">
        <Twitter sx={{ fill: "white", width: 20 }} />
      </a>

      <a href="https://www.instagram.com/botelapp/" target="_blank" rel="noreferrer">
        <Instagram sx={{ fill: "white", width: 20 }} />
      </a>
    </Container>
  );
};

const Container = styled(SoftBox)(() => ({
  display: "flex",
  gap: 6,
}));

export default SocialLinks;
