import React, { useEffect, useRef, useState } from "react";

// hooks
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useAnimations } from "../../hooks/useAnimations";
import { useAnimationControls } from "framer-motion";

// components
import { IllustrationsContainer } from "../Illustrations/IllustrationContainer";
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";
import AdvantageSectionLayout from "./AdvantageSectionLayout";

import { ADVANTAGES } from "../../constants";

// images
import PhoneImage from "../../../../assets/images/UnifiedInbox/Phone.svg";
import WhatsappImage from "../../../../assets/images/UnifiedInbox/Whatsapp.svg";
import AirBnbImage from "../../../../assets/images/UnifiedInbox/AirBnb.svg";

import type { Animations } from "../../types";

enum AnimationItems {
  Phone,
  WhatsApp,
  AirBnb,
}

const desktopAnimations = [
  {
    key: AnimationItems.Phone,
    element: PhoneImage,
    order: 0,
    start: { opacity: 0, top: 100, scale: 1, left: 0 },
    end: { opacity: 1, top: 0 },
  },
  {
    key: AnimationItems.WhatsApp,
    element: WhatsappImage,
    order: 1,
    start: { opacity: 0, scale: 1, left: 518, top: 110 },
    end: { opacity: 1, left: 318 },
  },
  {
    key: AnimationItems.AirBnb,
    element: AirBnbImage,
    order: 2,
    start: { opacity: 0, scale: 1, left: 88, top: 220 },
    end: { opacity: 1, left: 288 },
  },
];

const mobileAnimations = [
  {
    key: AnimationItems.Phone,
    element: PhoneImage,
    order: 0,
    start: { opacity: 0, top: 100, left: -100, scale: 1 },
    end: { opacity: 1, left: 35 },
  },
  {
    key: AnimationItems.WhatsApp,
    element: WhatsappImage,
    order: 1,
    start: { opacity: 0, left: -100, top: 620, scale: 0.8 },
    end: { opacity: 1, left: -30 },
  },
  {
    key: AnimationItems.AirBnb,
    element: AirBnbImage,
    order: 2,
    start: { opacity: 0, scale: 0.8, left: 100, top: 700 },
    end: { opacity: 1, left: -30 },
  },
];

const UnifiedInbox = () => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();
  const [animations, setAnimations] = useState<Animations<AnimationItems>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? mobileAnimations : desktopAnimations);
  }, [mobileView]);

  useAnimations({
    animations,
    sectionRef,
    controls,
    delay: 0.7,
  });

  return (
    <AdvantageSectionLayout
      tabLabel="Unified Inbox"
      title="Email, SMS, Whatsapp, Airbnb - all in one inbox, under your control"
      description="Effortlessly consolidate guest communication, improve performance with AI-driven features, and never miss a booking or upsell opportunity through smart automation in one unified inbox."
      onCtaClick={() => null}
      contentColumnStyles={{ pt: { xs: 0, lg: 12 } }}
      illustration={
        <IllustrationsContainer sx={{ minHeight: { xs: 850, lg: 650 }, ml: { xs: 0, lg: 10 } }}>
          {animations.map((a) => (
            <AnimatedIllustration
              key={mobileView ? `${a.key}-mobile` : a.key}
              src={a.element}
              controls={controls}
              animationOrder={a.order}
              initial={a.start}
            />
          ))}
        </IllustrationsContainer>
      }
      id={ADVANTAGES.UNIFIED_INBOX}
      ref={sectionRef}
    />
  );
};

export default UnifiedInbox;
