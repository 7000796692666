import { useTheme } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";

export const useWindowSize = (): {
  size: { width: number; height: number };
  mobileView: boolean;
  tabletView: boolean;
  desktopView: boolean;
} => {
  const theme = useTheme();
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [mobileView, setMobileView] = useState(window.innerWidth <= theme.breakpoints.values.md);
  const [tabletView, setTabletView] = useState(
    window.innerWidth >= theme.breakpoints.values.lg &&
      window.innerWidth < theme.breakpoints.values.xl
  );
  const [desktopView, setDesktopView] = useState(window.innerWidth >= theme.breakpoints.values.xl);

  const updateSize = () => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
    setMobileView(window.innerWidth <= theme.breakpoints.values.md);
    setTabletView(window.innerWidth >= theme.breakpoints.values.lg);
    setDesktopView(window.innerWidth >= theme.breakpoints.values.xl);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize, true);

    return () => {
      window.removeEventListener("resize", updateSize, true);
    };
  }, []);

  return { size, mobileView, tabletView, desktopView };
};
