import { styled } from "@mui/material";
import React, { forwardRef } from "react";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import Image from "next/image";

import AdvantagesBackgroundIllustration from "../../../../assets/images/advantages_background_img.svg";
import SoftButton from "../../../../components/SoftButton";
import { useRouter } from "next/router";

type AdvantageSectionLayoutProps = {
  tabLabel: string;
  title: string;
  description: string;
  onCtaClick: () => void;
  illustration: React.ReactNode;
  id: string;
  contentColumnStyles?: any;
};

const AdvantageSectionLayout = forwardRef<HTMLDivElement, AdvantageSectionLayoutProps>(
  (props, ref) => {
    const { tabLabel, title, description, onCtaClick, illustration, id, contentColumnStyles } =
      props;

    const router = useRouter();

    const handleCtaClick = () => {
      router.push("/app/inbox");
    };

    return (
      <Section id={id} ref={ref} sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <Content sx={{ ...contentColumnStyles }}>
          <TabLabel sx={{ textAlign: { xs: "center", lg: "left" } }}>{tabLabel}</TabLabel>
          <Title sx={{ textAlign: { xs: "center", lg: "left" }, fontSize: { xs: 24, lg: 30 } }}>
            {title}
          </Title>
          <Description
            sx={{ textAlign: { xs: "center", lg: "left" }, fontSize: { xs: 14, lg: 16 } }}
          >
            {description}
          </Description>
          <CtaButton
            color="primary"
            variant="outlined"
            onClick={handleCtaClick}
            sx={{ alignSelf: { xs: "center", lg: "start" } }}
          >
            Get started
          </CtaButton>
        </Content>

        <Illustrations sx={{ marginLeft: { xs: 0, lg: 2.5 } }}>{illustration}</Illustrations>

        <StyledSectionIllustration
          src={AdvantagesBackgroundIllustration}
          alt="background illustration"
          sx={{ right: { xs: -40, lg: 0 } }}
        />
      </Section>
    );
  }
);

const Section = styled(SoftBox)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  position: "relative",
}));

const Content = styled(SoftBox)(() => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: 353,
  zIndex: 2,
}));

const StyledSectionIllustration = styled(Image)(() => ({
  position: "absolute",
  top: -100,
  zIndex: 1,
}));

const Illustrations = styled(SoftBox)(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
}));

const TabLabel = styled(SoftTypography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  fontSize: 16,
  marginBottom: 18,
}));

const Title = styled(SoftTypography)(({ theme }) => ({
  color: "dark.heading",
  fontWeight: 700,
  fontSize: 30,
  lineHeight: "41.1px",
  marginBottom: 18,
}));

const Description = styled(SoftTypography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 16,
  lineHeight: "24px",
  marginBottom: 18,
}));

const CtaButton = styled(SoftButton)(() => ({
  borderRadius: 24,
  padding: "11px 24px",
  width: "max-content",
  lineHeight: 1,
}));

export default AdvantageSectionLayout;
