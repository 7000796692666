import React from "react";
import { styled } from "@mui/material";
import { useRouter } from "next/router";

import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftTypography from "../../../components/SoftTypography";

const FreeTrial = () => {
  const router = useRouter();

  const handleCtaClick = () => {
    router.push("/app/inbox");
  };

  return (
    <Container sx={{ py: { xs: 5, md: 7, lg: 14.75 }, px: 2 }}>
      <Content
        sx={{ flexDirection: { xs: "column", lg: "row" }, gap: { xs: 5, md: 9, lg: 15.75 } }}
      >
        <Column sx={{ maxWidth: 450 }}>
          <Title sx={{ textAlign: { xs: "center", md: "left" } }}>Save time and money today</Title>
          <Subtitle sx={{ textAlign: { xs: "center", md: "left" } }}>
            Join hundreds of happy customers already using Botel.
          </Subtitle>
        </Column>
        <Column sx={{ maxWidth: 300, gap: 2.25 }}>
          <StartButton
            color="warning"
            sx={{ color: "dark.heading", px: "43px" }}
            onClick={handleCtaClick}
          >
            Get started today!
          </StartButton>
          <Text sx={{ textAlign: { xs: "center", md: "left" } }}>
            No onboarding fee - Get started in seconds.
          </Text>
        </Column>
      </Content>
    </Container>
  );
};

const Container = styled(SoftBox)(() => ({
  background: "linear-gradient(310deg, #7928CA 0%, #5724EA 100%)",
  width: "100%",
}));

const Content = styled(SoftBox)(() => ({
  display: "flex",
  gap: 126,
  margin: "0 auto",
  maxWidth: "max-content",
  alignItems: "center",
}));

const Column = styled(SoftBox)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const Title = styled(SoftTypography)(() => ({
  fontSize: 36,
  lineHeight: "47px",
  fontWeight: 700,
  color: "white",
  marginBottom: 12,
  letterSpacing: -0.8,
}));

const Subtitle = styled(SoftTypography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: "27px",
  color: "white",
  opacity: 0.6,
}));

const StartButton = styled(SoftButton)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  textTransform: "capitalize",
  padding: "21.5px 86px",
}));

const Text = styled(SoftTypography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: "21px",
  color: "white",
}));

export default FreeTrial;
