import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material";
import debounce from "lodash.debounce";

import SoftBox from "../../../../components/SoftBox";

import { ADVANTAGES } from "../../constants";

import type { HomepageSectionsTabs } from "../../types";

type SectionTabsProps = {
  tabs: HomepageSectionsTabs;
  selected: ADVANTAGES;
  onTabClick: (arg: ADVANTAGES) => void;
};

const SectionTabs: React.FC<SectionTabsProps> = (props) => {
  const { tabs, selected, onTabClick } = props;

  const tabsContainerRef = useRef<HTMLDivElement>(null);

  const scrollIntoView = () => {
    const tabElement = document.getElementById(`${selected}-tab`);
    if (tabElement && tabsContainerRef.current) {
      const OFFSET = 100;
      tabsContainerRef.current.scrollLeft = tabElement.offsetLeft - OFFSET;
    }
  };

  const debouncedHorizontalScroll = debounce(scrollIntoView, 150);

  useEffect(() => {
    debouncedHorizontalScroll();
  }, [selected]);

  return (
    <ContentTabs sx={{ pb: { xs: 1.5, lg: 0 }, px: { xs: 2, lg: 0 } }} ref={tabsContainerRef}>
      {tabs.map((t) => (
        <StyledTab
          id={`${t.id}-tab`}
          key={t.id}
          color="primary"
          onClick={() => onTabClick(t.id)}
          sx={(theme) => ({
            fontSize: { xs: 12, lg: 14 },
            color: selected === t.id ? "primary.main" : "dark.heading",
            borderColor: selected === t.id ? "primary.main" : "transparent !important",
            height: { xs: 40, lg: 45 },
            background:
              selected === t.id ? `${theme.palette.primary.main}14 !important` : "transparent",
            "&:focus:not(:hover)": { boxShadow: "none" },
            "&:hover": {
              color: "primary.main",
              transform: selected !== t.id ? "scale(1.03)" : "none",
            },
          })}
        >
          {t.label}
        </StyledTab>
      ))}
    </ContentTabs>
  );
};

const ContentTabs = styled(SoftBox)(() => ({
  display: "flex",
  gap: 26,
  maxWidth: "100vw",
  overflow: "auto",
  whiteSpace: "nowrap",
  "::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
  scrollBehavior: "smooth",
}));

const StyledTab = styled(SoftBox)(() => ({
  fontSize: 14,
  fontWeight: 700,
  fontFamily: "Helvetica",
  textTransform: "capitalize",
  padding: "12px 16px",
  height: 45,
  borderRadius: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: "1px solid",
  transition: "0.2s ease all",
}));

export default SectionTabs;
