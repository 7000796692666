import React from "react";
import { Homepage } from "../features/homepage/components";
import { UserContextProvider } from "../context/userDataContext";

const Home = () => {
  return (
    <UserContextProvider>
      <Homepage />
    </UserContextProvider>
  );
};

export default Home;
