import React from "react";
import SoftBox from "../../../components/SoftBox";
import { styled } from "@mui/material";
import MainSection from "./MainSection";
import AdvantagesSection from "./AdvantagesSection";
import { Footer } from "./Footer";
import FreeTrial from "./FreeTrial";
import SignUpCTA from "./SignUpCTA";
import TaskManagerSection from "./TaskManagerSection";

// TODO: mobile animations

const HomepageLayout = () => {
  return (
    <Container>
      <MainSection />
      <TaskManagerSection />
      <AdvantagesSection />
      <FreeTrial />
      <SignUpCTA />
      <Footer />
    </Container>
  );
};

const Container = styled(SoftBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export default HomepageLayout;
