import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material";

// hooks
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useAnimations } from "../../hooks/useAnimations";
import { useAnimationControls } from "framer-motion";

// components
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";
import SoftBox from "../../../../components/SoftBox";

// images
import GuestMessage from "../../../../assets/images/HeroSection/AcGuestMessageOne.svg";
import ArrowRight from "../../../../assets/images/HeroSection/ArrowRight.svg";
import Property from "../../../../assets/images/HeroSection/Property.svg";
import PropertyDescription from "../../../../assets/images/HeroSection/PropertyDescription.svg";
import ArrowLeft from "../../../../assets/images/HeroSection/ArrowLeft.svg";
import BotResponseAC from "../../../../assets/images/HeroSection/BotResponseAC.svg";

import type { Animations } from "../../types";

enum AcScenarioAnimations {
  GuestMessage,
  ArrowRight,
  Property,
  Description,
  ArrowLeft,
  BotResponse,
}

const desktopAnimations: Animations<AcScenarioAnimations> = [
  {
    key: AcScenarioAnimations.GuestMessage,
    element: GuestMessage,
    order: 0,
    start: { opacity: 0, scale: 1, left: 0, top: 166 },
    end: { opacity: 1, top: 66 },
  },
  {
    key: AcScenarioAnimations.ArrowRight,
    element: ArrowRight,
    order: 1,
    start: { opacity: 0, scale: 0, left: 419, top: 17, rotate: 330 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AcScenarioAnimations.Property,
    element: Property,
    order: 2,
    start: { opacity: 0, scale: 1, left: 575, top: 100 },
    end: { opacity: 1, top: 0 },
  },
  {
    key: AcScenarioAnimations.Description,
    element: PropertyDescription,
    order: 3,
    start: { opacity: 0, left: 575, top: 244, scale: 1 },
    end: { opacity: 1, top: 144 },
  },
  {
    key: AcScenarioAnimations.ArrowLeft,
    element: ArrowLeft,
    order: 4,
    start: { opacity: 0, scale: 0, left: 479, top: 360 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AcScenarioAnimations.BotResponse,
    element: BotResponseAC,
    order: 5,
    start: { opacity: 0, scale: 1, left: 100, top: 298 },
    end: { opacity: 1, top: 198 },
  },
];

type ACScenarioProps = {
  onAnimationEnd: () => void;
};

const ACScenario: React.FC<ACScenarioProps> = ({ onAnimationEnd }) => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();

  const [animations, setAnimations] = useState<Animations<AcScenarioAnimations>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? [] : desktopAnimations);
  }, [mobileView]);

  useAnimations({ animations, sectionRef, controls, delay: 1, onAnimationEnd });

  return (
    <AnimationsContainer ref={sectionRef}>
      {animations.map((a) => (
        <AnimatedIllustration
          key={mobileView ? `${a.key}-mobile` : a.key}
          src={a.element}
          controls={controls}
          animationOrder={a.order}
          initial={a.start}
        />
      ))}
    </AnimationsContainer>
  );
};

const AnimationsContainer = styled(SoftBox)(() => ({
  flex: 1,
  maxWidth: 928,
  width: "100%",
  height: "100%",
  margin: "0 auto",
  position: "relative",
  marginTop: 40,
}));

export default ACScenario;
