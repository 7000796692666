import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material";

// hooks
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useAnimations } from "../../hooks/useAnimations";
import { useAnimationControls } from "framer-motion";

// components
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";
import SoftBox from "../../../../components/SoftBox";

// images
import Instructions from "../../../../assets/images/HeroSection/Instructions.svg";
import ArrowRight from "../../../../assets/images/HeroSection/ArrowRight.svg";
import BookingBotResponse from "../../../../assets/images/HeroSection/BookingBotResponse.svg";
import ArrowLeftBooking from "../../../../assets/images/HeroSection/ArrowLeftBooking.svg";
import BookingGuestMsg from "../../../../assets/images/HeroSection/BookingGuestMsg.svg";
import PaymentLog from "../../../../assets/images/HeroSection/PaymentLog.svg";

import type { Animations } from "../../types";

enum BookingScenarioAnimations {
  Instruction,
  ArrowRight,
  BotSuggestion,
  ArrowLeft,
  GuestMessage,
  BookingConfirmed,
}

const desktopAnimations: Animations<BookingScenarioAnimations> = [
  {
    key: BookingScenarioAnimations.Instruction,
    element: Instructions,
    order: 0,
    start: { opacity: 0, scale: 1, left: 30, top: 100 },
    end: { opacity: 1, top: 0 },
  },
  {
    key: BookingScenarioAnimations.ArrowRight,
    element: ArrowRight,
    order: 1,
    start: { opacity: 0, scale: 0, left: 318, top: 6, rotate: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: BookingScenarioAnimations.BotSuggestion,
    element: BookingBotResponse,
    order: 2,
    start: { opacity: 0, scale: 1, left: 410, top: 100 },
    end: { opacity: 1, top: 0 },
  },
  {
    key: BookingScenarioAnimations.ArrowLeft,
    element: ArrowLeftBooking,
    order: 3,
    start: { opacity: 0, left: 379, top: 155, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: BookingScenarioAnimations.GuestMessage,
    element: BookingGuestMsg,
    order: 4,
    start: { opacity: 0, scale: 1, left: 0, top: 279 },
    end: { opacity: 1, top: 179 },
  },
  {
    key: BookingScenarioAnimations.BookingConfirmed,
    element: PaymentLog,
    order: 5,
    start: { opacity: 0, scale: 1, left: 30, top: 384 },
    end: { opacity: 1, top: 284 },
  },
];

type BookingScenarioProps = {
  onAnimationEnd: () => void;
};

const BookingScenario: React.FC<BookingScenarioProps> = ({ onAnimationEnd }) => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();

  const [animations, setAnimations] = useState<Animations<BookingScenarioAnimations>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? [] : desktopAnimations);
  }, [mobileView]);

  useAnimations({ animations, sectionRef, controls, delay: 1, onAnimationEnd });

  return (
    <AnimationsContainer ref={sectionRef}>
      {animations.map((a) => (
        <AnimatedIllustration
          key={mobileView ? `${a.key}-mobile` : a.key}
          src={a.element}
          controls={controls}
          animationOrder={a.order}
          initial={a.start}
        />
      ))}
    </AnimationsContainer>
  );
};

const AnimationsContainer = styled(SoftBox)(() => ({
  flex: 1,
  maxWidth: 733,
  width: "100%",
  height: "100%",
  margin: "0 auto",
  position: "relative",
  marginTop: 76,
}));

export default BookingScenario;
