import { HomepageNavLink } from "../types";

export const homepageNavLinks: HomepageNavLink[] = [
  { label: "Features", to: "#" },
  { label: "Industries", to: "#" },
  { label: "How it works", to: "#" },
  { label: "Pricing", to: "#" },
];

export enum ADVANTAGES {
  UNIFIED_INBOX = "UNIFIED_INBOX",
  UPSELLS = "UPSELLS",
  AI_POWERED_MESSAGES = "AI_POWERED_MESSAGES",
  TASK_AND_INCIDENT_MANAGEMENT = "TASK_AND_INCIDENT_MANAGEMENT",
  SATISFIED_GUESTS = "SATISFIED_GUESTS",
  REVIEW_MANAGEMENT = "REVIEW_MANAGEMENT",
}

export const advantagesSections = [
  { id: ADVANTAGES.UNIFIED_INBOX, label: "Unified Inbox" },
  { id: ADVANTAGES.UPSELLS, label: "Upsells" },
  { id: ADVANTAGES.AI_POWERED_MESSAGES, label: "AI Powered Messages" },
  { id: ADVANTAGES.TASK_AND_INCIDENT_MANAGEMENT, label: "Task & Incident Management" },
  { id: ADVANTAGES.SATISFIED_GUESTS, label: "Satisfied Guests" },
  { id: ADVANTAGES.REVIEW_MANAGEMENT, label: "Review Management" },
];
