import React, { useEffect, useRef, useState } from "react";
import AdvantageSectionLayout from "./AdvantageSectionLayout";
import { ADVANTAGES } from "../../constants";

import type { Animations } from "../../types";

import AiSentimentSuggestion from "../../../../assets/images/TaskAndIncidentManagement/AiSentimentSuggestion.svg";
import ArrowBottom from "../../../../assets/images/TaskAndIncidentManagement/ArrowBottom.svg";
import ArrowRight from "../../../../assets/images/TaskAndIncidentManagement/ArrowRight.svg";
import ArrowUp from "../../../../assets/images/TaskAndIncidentManagement/ArrowUp.svg";
import BotSentimentAnalisys from "../../../../assets/images/TaskAndIncidentManagement/BotSentimentAnalisys.svg";
import CentralizedTeam from "../../../../assets/images/TaskAndIncidentManagement/CentralizedTeam.svg";
import FinalBotSuggestion from "../../../../assets/images/TaskAndIncidentManagement/FimalBotSuggestion.svg";
import GuestResponse from "../../../../assets/images/TaskAndIncidentManagement/GuestResponse.svg";
import InstructionCondition from "../../../../assets/images/TaskAndIncidentManagement/InstructionCondition.svg";
import ServiceIssue from "../../../../assets/images/TaskAndIncidentManagement/ServiceIssue.svg";
import TeamMessage from "../../../../assets/images/TaskAndIncidentManagement/TeamMessage.svg";
import { useAnimationControls } from "framer-motion";
import { useAnimations } from "../../hooks/useAnimations";
import { IllustrationsContainer } from "../Illustrations/IllustrationContainer";
import AnimatedIllustration from "../Illustrations/AnimatedIllustration";
import { useWindowSize } from "../../../../hooks/useWindowSize";

// team -> centrilized -> service issue ->
// guest response -> bot sentiment -> arrow right
// instruction ->arrow bottom -> sentiment suggestion
// arrow up -> final bot answer

enum AnimationItems {
  Team,
  Centralized,
  Service,
  GuestResponse,
  BotSentiment,
  ArrowRight,
  Instruction,
  ArrowBottom,
  SentimentSuggestion,
  ArrowUp,
  FinalAnswer,
}

const desktopAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.Team,
    element: TeamMessage,
    order: 0,
    start: { opacity: 0, top: 100, left: 0, scale: 1, zIndex: 0 },
    end: { opacity: 1, top: 0 },
  },
  {
    key: AnimationItems.Centralized,
    element: CentralizedTeam,
    order: 1,
    start: { opacity: 0, top: 50, left: 320, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.Service,
    element: ServiceIssue,
    order: 2,
    start: { opacity: 0, top: -110, left: 325, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.GuestResponse,
    element: GuestResponse,
    order: 3,
    start: { opacity: 0, top: 220, left: 60, scale: 1 },
    end: { opacity: 1, top: 120 },
  },
  {
    key: AnimationItems.BotSentiment,
    element: BotSentimentAnalisys,
    order: 4,
    start: { opacity: 0, left: 450, top: 110, scale: 1 },
    end: { opacity: 1, left: 350 },
  },
  {
    key: AnimationItems.ArrowRight,
    element: ArrowRight,
    order: 5,
    start: { opacity: 0, left: 490, top: 130, scale: 0, rotate: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.Instruction,
    element: InstructionCondition,
    order: 6,
    start: { opacity: 0, left: 540, top: 0, scale: 1 },
    end: { opacity: 1, top: 70 },
  },
  {
    key: AnimationItems.ArrowBottom,
    element: ArrowBottom,
    order: 7,
    start: { opacity: 0, left: 580, top: 265, scale: 0, rotate: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.SentimentSuggestion,
    element: AiSentimentSuggestion,
    order: 8,
    start: { opacity: 0, left: 390, top: 390, scale: 1 },
    end: { opacity: 1, top: 330 },
  },
  {
    key: AnimationItems.ArrowUp,
    element: ArrowUp,
    order: 9,
    start: { opacity: 0, left: 360, top: 380, scale: 0, rotate: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.FinalAnswer,
    element: FinalBotSuggestion,
    order: 10,
    start: { opacity: 0, left: 20, top: 310, scale: 1 },
    end: { opacity: 1, top: 210 },
  },
];

const mobileAnimations: Animations<AnimationItems> = [
  {
    key: AnimationItems.Team,
    element: TeamMessage,
    order: 0,
    start: { opacity: 0, top: 200, left: -50, scale: 0.8, zIndex: 2 },
    end: { opacity: 1, top: 120 },
  },
  {
    key: AnimationItems.Centralized,
    element: CentralizedTeam,
    order: 1,
    start: { opacity: 0, top: 30, left: 15, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.Service,
    element: ServiceIssue,
    order: 2,
    start: { opacity: 0, top: 0, left: 225, scale: 0 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.GuestResponse,
    element: GuestResponse,
    order: 3,
    start: { opacity: 0, top: 320, left: -50, scale: 0.8 },
    end: { opacity: 1, top: 220 },
  },
  {
    key: AnimationItems.BotSentiment,
    element: BotSentimentAnalisys,
    order: 4,
    start: { opacity: 0, left: 330, top: 200, scale: 0.9 },
    end: { opacity: 1, left: 190 },
  },
  {
    key: AnimationItems.ArrowRight,
    element: ArrowRight,
    order: 5,
    start: { opacity: 0, left: 300, top: 310, scale: 0, rotate: 45 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.Instruction,
    element: InstructionCondition,
    order: 6,
    start: { opacity: 0, left: 202, top: 445, scale: 0.75 },
    end: { opacity: 1, top: 352 },
  },
  {
    key: AnimationItems.ArrowBottom,
    element: ArrowBottom,
    order: 7,
    start: { opacity: 0, left: 277, top: 520, scale: 0, rotate: 45 },
    end: { opacity: 1, scale: 1 },
  },
  {
    key: AnimationItems.SentimentSuggestion,
    element: AiSentimentSuggestion,
    order: 8,
    start: { opacity: 0, left: -50, top: 600, scale: 0.8 },
    end: { opacity: 1, top: 500 },
  },
  {
    key: AnimationItems.ArrowUp,
    element: ArrowUp,
    order: 9,
    start: { opacity: 0, left: 30, top: 444, scale: 0, rotate: 45 },
    end: { opacity: 1, scale: 0.8 },
  },
  {
    key: AnimationItems.FinalAnswer,
    element: FinalBotSuggestion,
    order: 10,
    start: { opacity: 0, left: -30, top: 390, scale: 0.8, zIndex: 2 },
    end: { opacity: 1, top: 290 },
  },
];

const TaskAndIncidentManagement = () => {
  const sectionRef = useRef(null);
  const controls = useAnimationControls();

  const [animations, setAnimations] = useState<Animations<AnimationItems>>([]);

  const { mobileView } = useWindowSize();

  useEffect(() => {
    setAnimations(mobileView ? mobileAnimations : desktopAnimations);
  }, [mobileView]);

  useAnimations({ animations, sectionRef, controls, delay: 0.7 });

  return (
    <AdvantageSectionLayout
      tabLabel="AI Task & Incident Management"
      title="Let AI Handle Task Creation and Issue Identification"
      description="65% of operations managers feel burden to create manual task that occur. Let our AI take care of identify issues and creating tasks so you can focus on following up with your staff to see if the task is done."
      onCtaClick={() => null}
      illustration={
        <IllustrationsContainer
          sx={{ marginTop: { xs: 3, lg: 0 }, minHeight: { xs: 700, lg: 650 } }}
        >
          {animations.map((a) => (
            <AnimatedIllustration
              key={a.key}
              src={a.element}
              controls={controls}
              animationOrder={a.order}
              initial={a.start}
            />
          ))}
        </IllustrationsContainer>
      }
      id={ADVANTAGES.TASK_AND_INCIDENT_MANAGEMENT}
      ref={sectionRef}
    />
  );
};

export default TaskAndIncidentManagement;
