import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material";

import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";

import {
  UnifiedInbox,
  Upsells,
  AiPoweredMessages,
  TaskAndIncidentManagement,
  SatisfiedGuests,
  ReviewManagement,
} from "./Sections";

import { ADVANTAGES, advantagesSections } from "../constants";

import { throttle } from "../../../utility/throttle";
import { useWindowSize } from "../../../hooks/useWindowSize";
import SectionTabs from "./Navigation/SectionTabs";

const STICKY_HEADER_OFFSET = 320;

const AdvantagesSection = () => {
  const [selectedTab, setSelectedTab] = useState(advantagesSections[0].id);

  const sectionsAnchors = useRef<{ id: ADVANTAGES; yPos: number }[]>([]);

  const { mobileView } = useWindowSize();

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  };

  const handleTabClick = (t: ADVANTAGES) => {
    scrollIntoViewWithOffset(`#${t}`, STICKY_HEADER_OFFSET);
  };

  const handleScroll = () => {
    const anchors = sectionsAnchors.current;
    if (anchors.length > 0) {
      const currentSection = anchors.find((anchor, index) => {
        if (anchors[index + 1]) {
          return (
            window.scrollY + STICKY_HEADER_OFFSET >= anchor.yPos &&
            window.scrollY + STICKY_HEADER_OFFSET <= anchors[index + 1].yPos
          );
        }

        return window.scrollY + STICKY_HEADER_OFFSET >= anchor.yPos;
      });

      if (currentSection) {
        setSelectedTab(currentSection.id);
      }
    }
  };

  useEffect(() => {
    const anchors: { id: ADVANTAGES; yPos: number }[] = [];

    advantagesSections.forEach((section) => {
      const s = document.querySelector(`#${section.id}`);
      if (s) {
        anchors.push({
          id: section.id,
          yPos:
            s.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            STICKY_HEADER_OFFSET,
        });
      }
    });

    sectionsAnchors.current = anchors;

    document.addEventListener("scroll", throttle(handleScroll, 100));

    return () => document.removeEventListener("scroll", throttle(handleScroll, 100));
  }, [mobileView]);

  return (
    <Section sx={{ px: { xs: 2.5, md: 0 } }}>
      <StickyArea sx={{ py: 3, mb: { xs: 5, md: 10 } }}>
        <PrimaryTitle
          variant="h2"
          fontWeight="bold"
          textAlign="center"
          sx={{ fontSize: { xs: 30, md: 36 } }}
        >
          One place to run your rentals
        </PrimaryTitle>

        <SecondaryTitle
          fontSize="large"
          variant="h4"
          textAlign="center"
          sx={{ fontSize: { xs: 14, md: 18 } }}
        >
          Streamline your operations and guest communications
        </SecondaryTitle>

        <SectionTabs tabs={advantagesSections} selected={selectedTab} onTabClick={handleTabClick} />
      </StickyArea>

      <ScrollableContent sx={{ px: { xs: 0, md: 2.5 } }}>
        <UnifiedInbox />
        <Upsells />
        <AiPoweredMessages />
        <TaskAndIncidentManagement />
        <SatisfiedGuests />
        <ReviewManagement />
      </ScrollableContent>
    </Section>
  );
};

const Section = styled(SoftBox)(() => ({
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  position: "relative",
}));

const StickyArea = styled(SoftBox)(({ theme }) => ({
  paddingTop: 70,
  paddingBottom: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "sticky",
  width: "100%",
  top: 0,
  background: theme.palette.background.default,
  zIndex: 3,
}));

const ScrollableContent = styled(SoftBox)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.xl,
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  overflow: "hidden",
}));

const PrimaryTitle = styled(SoftTypography)(() => ({
  color: "black",
  marginBottom: 8,
}));

const SecondaryTitle = styled(SoftTypography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginBottom: 24,
}));

export default AdvantagesSection;
